import moment from 'moment';

export const getTimeErrors = horarios => {
    let error = false;

    horarios.forEach(item => {
        // Validação do horário principal
        if (item.aberto) {
            if (!item.horario) {
                error = `O horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
                return;
            }

            let horarios = item.horario.split(' - ');
            if (horarios.length !== 2) {
                error = `O horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
            }

            let horarioValido = horarios.every(horario => moment(horario, 'HH:mm', true).isValid());
            if (!horarioValido) {
                error = `Os horários de ${item.diaSemana} devem estar no formato HH:mm e serem válidos`;
            }

            let horarioInicial = moment(horarios[0], 'HH:mm');
            let horarioFinal = moment(horarios[1], 'HH:mm');
            if (!horarioInicial.isBefore(horarioFinal)) {
                error = `O horário de ${item.diaSemana} deve estar entre 00:00 e 23:59`;
            }
        }

        // Validação do segundo horário, se ativo
        if (item.segundoHorarioAtivo) {
            if (!item.segundoHorario) {
                error = `O segundo horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
                return;
            }

            let horarios = item.segundoHorario.split(' - ');
            if (horarios.length !== 2) {
                error = `O segundo horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
            }

            let segundoHorarioValido = horarios.every(horario => moment(horario, 'HH:mm', true).isValid());
            if (!segundoHorarioValido) {
                error = `Os horários do segundo horário de ${item.diaSemana} devem estar no formato HH:mm e serem válidos`;
            }

            let horarioInicial = moment(horarios[0], 'HH:mm');
            let horarioFinal = moment(horarios[1], 'HH:mm');
            if (!horarioInicial.isBefore(horarioFinal)) {
                error = `O horário inicial do segundo horário de ${item.diaSemana} deve estar entre 00:00 e 23:59`;
            }
        }
    });

    return error;
};

export const isValidCpf = cpf => {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }

    let sum;
    let remainder;

    sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }
    if (remainder !== parseInt(cpf.charAt(9))) {
        return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }
    if (remainder !== parseInt(cpf.charAt(10))) {
        return false;
    }

    return true;
}

export const isValidEmail = email => {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
}

export const isValidCep = cep => {
    const cepPattern = /^[0-9]{8}$/;
    return cepPattern.test(cep?.replace(/\D/g, ''));
}

export const isValidPersonName = name => {
    if (!name || typeof name !== 'string') {
        return false;
    }

    const words = name.trim().split(/\s+/);

    return words.length >= 2;
}
